
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onMounted } from 'vue';
import {addConnectionListener} from '@/utils/network';
import {checkAndSubmitQueue, connectionSubmissionListener} from '@/modules/api/submissionQueue';

export default {
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    onMounted(async () => {
      await registerSubmissionListener();
      await checkAndSubmitQueue();
    });

    const registerSubmissionListener = () => {
      addConnectionListener(connectionSubmissionListener);
    };


    return;
  },
}
