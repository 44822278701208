import { Answersheet } from "../model/answersheet";
import answersheetRepository from '@/modules/questionnaires/data/repositories/answersheetRepository';
import accountRepository from '@/modules/account/data/repositories/accountRepository';
const { read, readMany, exists, update } = answersheetRepository;

const create = async (answersheet: Answersheet) => {
  answersheet.id = await answersheetRepository.create(answersheet);
  const account = await accountRepository.read();
  account.answersheets.add(answersheet.id);
  await accountRepository.update(account);
  return answersheet.id;
};

const readAll = async () => {
  const account = await accountRepository.read();
  const ids = account.answersheets.keys();
  return await readMany(Array.from(ids));
};

const remove = async (id: string) => {
  await answersheetRepository.remove(id);
  const account = await accountRepository.read();
  account.answersheets.delete(id);
  await accountRepository.update(account);
};

const removeAll = async () => {
  const account = await accountRepository.read();
  const ids = account.answersheets.keys();
  for (const id of ids) {
    if (id) {
      // should always be true
      await answersheetRepository.remove(id);
      account.answersheets.delete(id);
    }
  }
  await accountRepository.update(account);
};

export const answersheetDal = {
  create,
  exists,
  read,
  readMany,
  readAll,
  update,
  remove,
  removeAll,
};
