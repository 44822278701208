import secureDb from "@/utils/secureDb";
import Serializer from "@/utils/serializer";
export const PREFIX = "submissionQueue";

const create = async (queue: SubmissionQueue) => {
  await secureDb.set(PREFIX, Serializer.stringify(queue));
};

const read = async (): Promise<SubmissionQueue> => {
  const json = await secureDb.get(PREFIX);
  if (json) return Serializer.parse(json) as SubmissionQueue;
  else throw new Error("Unable to read submissionQueue");
};

const exists = async (): Promise<boolean> => {
  try {
    await read();
    return true;
  } catch (e) {
    return false;
  }
};

const update = async (queue: SubmissionQueue) => {
  await secureDb.set(PREFIX, Serializer.stringify(queue));
};

const remove = async () => {
  await secureDb.remove(PREFIX);
};

/**
 * CRUD database access to account
 * This repository does not implement CRUDRepository interface
 * as submissionQueue does not have an id
 */
const accountCRUDRepository = {
  create,
  read,
  exists,
  update,
  remove,
};

export default accountCRUDRepository;
