import serialize from "serialize-javascript";

/**
 * Use serialization of yahoos' serialize-javascript
 * @see https://github.com/yahoo/serialize-javascript
 * @licence https://github.com/yahoo/serialize-javascript/blob/main/LICENSE
 */
export const stringify = serialize;

/**
 * Deserializes string
 * @param json Input
 * @returns Deserialized object
 */
export const parse = (json: string) => {
  return eval("(" + json + ")");
};

export const convertUndefined = <T>(obj: T, value: any): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertUndefined(item, value)) as any;
  } else if (typeof obj === "object" && obj !== null) {
    let result: any;
    if (obj instanceof Map) {
      result = Object.fromEntries(Array.from(obj.entries(), ([k, v]) => [k, convertUndefined(v, value)]));
    } else {
      result = { ...obj };
      for (const [key, val] of Object.entries(result)) {
        result[key] = convertUndefined(val, value);
      }
    }
    return result as T;
  } else if (obj === undefined) {
    return value;
  } else {
    return obj;
  }
}

export default { stringify, parse };
