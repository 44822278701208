import secureDb from "@/utils/secureDb";
import { v4 as uuidv4 } from "uuid";
import {Account} from '@/modules/account/data/model/account';
import AccountFactory from '@/modules/account/data/model/accountFactory';
import {accountDal} from '@/modules/account/data/dal/account';
import SubmissionQueueFactory from '@/modules/api/data/model/submissionQueueFactory';
import {submissionQueueDal} from '@/modules/api/data/dal/submissionQueue';

/**
 * Seeds database, i.e. creates an Account and submissionQueue
 */
const seed = async () => {
  // build account and set randomly generated pseudonym, email and ApiKey
  const account = AccountFactory.afterBuild((account: Account) => {
    account.pseudonym = uuidv4();
    account.email = account.pseudonym + "@hsc.pseudo";
    account.questionnaireApiKey = uuidv4();
  }).build();
  // create new account stub
  await accountDal.create(account);

  // create submissionQueue
  const submissionQueue = SubmissionQueueFactory.build();
  await submissionQueueDal.create(submissionQueue);
};

/**
 * Clears database and seeds database
 */
const reseed = async () => {
  // clear database
  await secureDb.clear();

  await seed();
};

const databaseSeeder = {
  seed,
  reseed,
};

export default databaseSeeder;
