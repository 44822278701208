import {toastController, ToastOptions} from '@ionic/vue';

export default function useToaster() {
    async function presentToast(options: ToastOptions) {
        if (!options.duration)
            options.duration = 3000;
        if (!options.position)
            options.position = 'top';
        if (!options.color)
            options.color = 'primary';
        const toast = await toastController.create(options);

        await toast.present();
    }

    async function presentWarningToast(options: ToastOptions) {
        if (!options.duration)
            options.duration = 3000;
        if (!options.position)
            options.position = 'top';
        if (!options.color)
            options.color = 'warning';
        const toast = await toastController.create(options);

        await toast.present();
    }

    async function presentErrorToast(options: ToastOptions) {
        if (!options.duration)
            options.duration = 5000;
        if (!options.position)
            options.position = 'top';
        if (!options.color)
            options.color = 'error';
        const toast = await toastController.create(options);

        await toast.present();
    }

    return {
        presentToast,
        presentWarningToast,
        presentErrorToast,
    };
}
