import { createRouter, createWebHistory } from '@ionic/vue-router';
import {RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw} from 'vue-router';
import TabsPage from '../pages/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        name: 'HomePage',
        component: () => import('@/modules/home/HomePage.vue'),
      },
      {
        path: 'home/settings',
        name: 'SettingsPage',
        component: () => import('@/modules/account/SettingsPage.vue')
      },
      {
        path: 'home/aboutTheApp',
        name: 'AboutTheAppPage',
        component: () => import('@/modules/home/AboutTheAppPage.vue')
      },
      {
        path: 'home/dprPage',
        name: 'DprPage',
        component: () => import('@/modules/home/DprPage.vue')
      },
      {
        path: 'questionnaires',
        name: 'QuestionnairesPage',
        component: () => import('@/modules/questionnaires/QuestionnairesPage.vue')
      },
      {
        path: 'history',
        name: 'HistoryPage',
        component: () => import('@/modules/questionnaires/HistoryPage.vue')
      },
      {
        path: 'history/:id',
        name: 'AnswersheetPage',
        component: () => import('@/modules/questionnaires/AnswersheetPage.vue')
      },
    ]
  },
  {
    path: "/questionnaires/:id",
    name: "Questionnaire",
    component: () => import("@/modules/questionnaires/QuestionnairePage.vue"),
  },
  {
    path: "/questionnaires/:id/:page(\\d+)/",
    name: "QuestionnairePage",
    component: () => import("@/modules/questionnaires/QuestionnairePage.vue"),
  },
  {
    path: '/questionnaires/thank-you',
    name: 'QuestionnaireThankYou',
    component: () => import('@/modules/questionnaires/QuestionnaireThankPage.vue')
  },
]

/**
 * Scroll to top on questionnaire pages
 * @param to
 * @param from
 * @param savedPosition
 */
const scrollBehavior = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: any | null,
) => {
  if (to.path.startsWith('/questionnaires/:id')) {
    return { x: 0, y: 0 };
  }
  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router
