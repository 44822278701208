import {ConnectionStatus} from '@capacitor/network';
import {isOnline} from '@/utils/network';
import {answersheetDal} from '@/modules/questionnaires/data/dal/answersheet';
import {submitAnswerSheet} from '@/modules/api/apiClient';
import {submissionQueueDal} from '@/modules/api/data/dal/submissionQueue';
import useToaster from '@/composables/useToaster';
export const connectionSubmissionListener = async (status: ConnectionStatus) => {
    if(status.connected) {
        console.log('Device is online, checking for answersheets to submit')
        await checkAndSubmitQueue();
    }
}

/**
 * Check if there are answersheets in the submission queue and submit them
 */
export const checkAndSubmitQueue = async () => {
    if(!await isOnline()) {
        await useToaster().presentToast({ message: 'Device is offline, will submit answersheets upon reconnection.' });
        console.log('Device is offline, skipping submission of answersheets')
        return;
    }

    const queue = await submissionQueueDal.read();
    if(!queue) {
        console.error('Queue is undefined');
        return;
    }
    const newQueue = [];
    for (let i = 0; i < queue.elements.length; i++) {
        const submission = queue.elements[i];
        const answersheet = await answersheetDal.read(submission.data);
        if (!answersheet) {
            console.error('Answersheet is undefined');
            return;
        }

        const response = await submitAnswerSheet(answersheet);
        if (response.status !== 201) {
            // Item not submitted, add to new queue
            console.error('Error while submitting answersheet, response: ' + response);
            await useToaster().presentErrorToast({ message: 'Answersheets not submitted' });
            newQueue.push(submission);
        }
    }
    if(newQueue.length !== queue.elements.length) {
        await useToaster().presentToast({ message: 'Answersheets submitted' });
        // update queue in account
        queue.elements = newQueue;
        await submissionQueueDal.update(queue);
    }
};
