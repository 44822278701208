import {Answersheet} from '@/modules/questionnaires/data/model/answersheet';
import {convertToDto} from '@/modules/questionnaires/utils/answersheet';
import {accountDal} from '@/modules/account/data/dal/account';
import {questionnaireDal} from '@/modules/questionnaires/data/dal/questionnaire';
import axios, {AxiosResponse} from 'axios';

axios.defaults.xsrfCookieName = undefined;
axios.defaults.xsrfHeaderName = undefined;

const BASE_URL = process.env.VUE_APP_SUBMISSION_SERVER_URL;

/**
 * Convert an answersheet to the format expected by the API
 */
interface AnswersheetSubmitRequest {
    answersheetId: string;
    userPseudo: string;
    questionnaireId: string;
    questionnaireVersion: number;
    questionnaireName: string | undefined;
    locale: string;
    client: Record<string, any>;
    answers: Record<string, any>;
}

/**
 * Converts an answersheet object to the format expected by the API
 * @param answersheet
 */
const convertAnswersheetToSubmitRequest = async (answersheet: Answersheet): Promise<AnswersheetSubmitRequest> => {
    const answersheetDto = convertToDto(answersheet);
    if(!answersheetDto.id) throw new Error('Answersheet id is undefined');
    const account = await accountDal.read();
    if (!account) throw new Error('Account is undefined');

    const questionnaireId = answersheetDto.attributes?.questionnaire_id? answersheetDto.attributes?.questionnaire_id : '';
    let questionnaireVersion = -1;
    let questionnaireName : string | undefined = undefined;
    if(answersheetDto.attributes?.questionnaire_id) {
        const questionnaire = await questionnaireDal.read(answersheetDto.attributes?.questionnaire_id);
        if(questionnaire) {
            questionnaireVersion = questionnaire.version;
            questionnaireName = questionnaire.title;
        }
    }
    const client = answersheetDto.attributes?.client? answersheetDto.attributes?.client : [];
    const answers = answersheetDto.attributes?.answers? answersheetDto.attributes?.answers : [];

    return {
        answersheetId: answersheetDto.id,
        userPseudo: account.pseudonym ? account.pseudonym : '',
        questionnaireId: questionnaireId,
        questionnaireVersion: questionnaireVersion,
        questionnaireName: questionnaireName,
        locale: answersheetDto.attributes?.locale? answersheetDto.attributes?.locale : '',
        client: client,
        answers: answers,
    };
}

/**
 * Submit an answersheet to the API
 * @param answerSheet
 * @returns {Promise<AxiosResponse>} The response from the API as AxiosResponse
 */
export const submitAnswerSheet = async (answerSheet: Answersheet): Promise<AxiosResponse> => {
    const request = await convertAnswersheetToSubmitRequest(answerSheet);
    return await axios.post(BASE_URL, request);
}
