import { defineStore } from 'pinia'
import {Account} from '@/modules/account/data/model/account';

interface State {
    account: Account | undefined;
}

export const useAccountStore = defineStore('account', {
    // convert to a function
    state: (): State => ({
        account: undefined,
    }),
    getters: {
        get: (state) => () =>
            state.account,
    },
    actions: {
        set (account: Account) {
            this.account = account;
        },
        // easily reset state using `$reset`
        clearAccount () {
            this.$reset()
        }
    }
})
