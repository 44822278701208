import { Account } from "../model/account";
import _ from "lodash";
import accountRepository from '@/modules/account/data/repositories/accountRepository';
import {useAccountStore} from '@/modules/account/data/stores/AccountStore';

const create = async (account: Account) => {
  await accountRepository.create(account);
  await useAccountStore().set(account);
};

const read = async () => {
  let account = _.cloneDeep(useAccountStore().get()) as Account | undefined;
  if (!account) {
    account = await accountRepository.read();
    await useAccountStore().set(account);
  }
  return account;
};

const update = async (account: Account) => {
  await useAccountStore().set(account);
  await accountRepository.update(account);
};

/**
 * Account module uses cache (store)
 */
export const accountDal = {
  create,
  read,
  update,
};
