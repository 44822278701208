import _ from "lodash";
import { Questionnaire } from "../model/questionnaire";
import {useQuestionnaireStore} from '@/modules/questionnaires/data/stores/questionnaireStore';

const create = async (questionnaire: Questionnaire) => {
  if (questionnaire.id != null) {
    const questionnaireStore = useQuestionnaireStore();
    questionnaireStore.add(questionnaire.id, questionnaire);
  }
};

const exists = async (id: string) => {
  const questionnaireStore = useQuestionnaireStore();
  return questionnaireStore.get(id) != null;
};

const read = async (id: string) => {
  const questionnaireStore = useQuestionnaireStore();
  return _.cloneDeep(questionnaireStore.get(id) as Questionnaire | undefined);
};

const readMany = async (ids: string[]): Promise<Questionnaire[]> => {
  const questionnaires: Questionnaire[] = [];
  const questionnaireStore = useQuestionnaireStore();
  for (const id of ids) {
    const questionnaire = _.cloneDeep(questionnaireStore.get(id));
    if (questionnaire) questionnaires.push(questionnaire);
  }
  return questionnaires;
};

const readIds = async () => {
  const questionnaireStore = useQuestionnaireStore();
  return _.cloneDeep(questionnaireStore.ids);
};

const readAll = async () => {
  const questionnaireStore = useQuestionnaireStore();
  return _.cloneDeep(questionnaireStore.getAll);
};

const update = async (questionnaire: Questionnaire): Promise<void> => {
  if (questionnaire.id != null) {
    const questionnaireStore = useQuestionnaireStore();
    await questionnaireStore.add(questionnaire.id, questionnaire);
  }
};

const remove = async (id: string) => {
  const questionnaireStore = useQuestionnaireStore();
  await questionnaireStore.remove(id);
};

/**
 * Questionnaire module, does not use cache
 */
export const questionnaireDal = {
  create,
  exists,
  read,
  readMany,
  readAll,
  readIds,
  update,
  remove,
};
