export class Questionnaire {
  public id: string | undefined = undefined;

  public version = -1;
  public title: string | undefined;
  public description: string | undefined;
  public translations: Map<string, QuestionnaireTranslation> = new Map<string, QuestionnaireTranslation>();
  public attributes: QuestionnaireAttributes | undefined;

  constructor(data: any) {
    this.id = data.id;
    this.version = data.version;
    this.title = data.title;
    this.description = data.description;

    this.translations = new Map<string, QuestionnaireTranslation>();
    if(data.translations) {
      data.translations.forEach((t: any) => {
        const languageKey = Object.keys(t);
        if (languageKey[0])
          this.translations.set(
              languageKey[0],
              new QuestionnaireTranslation(t[languageKey[0]])
          );
      });
    }

    this.attributes = new QuestionnaireAttributes();
    this.attributes.elements = new Map<string, QuestionnaireElement>();
    if(data.attributes?.elements) {

      data.attributes.elements.forEach((element: any) => {
        // check if every element in data.attributes.elements has a different element.label
        // if not, throw an error
        const elementLabel = element.label;
        const elementLabelCount = data.attributes.elements.filter((e: QuestionnaireElement) => e.label === elementLabel).length;
        if (elementLabelCount > 1) {
          const errorMessage = `Questionnaire ${data.id} has multiple elements with the same label ${elementLabel}.`;
          console.error(errorMessage);
          throw new Error(errorMessage);
        }

        this.attributes?.elements.set(element.label, new QuestionnaireElement(element));
      });
    }
  }
}

export class QuestionnaireAttributes {
  public elements: Map<string, QuestionnaireElement> = new Map<string, QuestionnaireElement>();
}

class QuestionnaireTranslation {
  public title: string | undefined;
  public description: string | undefined;

  constructor(data: any) {
    this.title = data.title;
    this.description = data.description;
  }
}

export enum QuestionType {
  SINGLE_CHOICE = "SingleChoice",
  MULTIPLE_CHOICE = "MultipleChoice",
  YES_OR_NO_SWITCH = "YesNoSwitch",
  SLIDER = "Slider",
  TEXT_STRING = "TextString",

  TEXT_NUMBER = "TextNumber",
  TEXT_AREA = "TextArea",
  TEXT_DATE = "TextDate",
}

export enum ElementType {
  QUESTION = "elements/questions",
  HEADLINE = "elements/headlines",
  TEXT = "elements/texts",
  PAGE_BREAK = "elements/pages",
}

export interface Translation {
  locale: string | undefined;
  question: string | undefined;
  answers: any;
  headline: string | undefined;
  text: string | undefined;
  hint: string | undefined;
}

export class QuestionnaireElement {
  position: number | undefined;
  isActive: boolean | undefined;
  condition: string | undefined;
  elementType: ElementType | undefined;
  isRequired: boolean | undefined;
  questionType: QuestionType | undefined;
  label: string | undefined;
  values: any;
  translations: Map<string, Translation> = new Map<string, Translation>();

  constructor(data: any) {
    this.position = data.position;
    this.isActive = data.is_active;
    this.condition = data.condition;
    this.elementType = data.elementtype;
    this.isRequired = data.required;
    this.questionType = data.questiontype;
    this.label = data.label;
    this.values = data.values;
    this.translations = new Map<string, Translation>();
    // iterate data.translations array
    if (data.translations)
      data.translations.forEach((translation: any) => {
        this.translations.set(translation.locale, translation);
      });
  }
}
