import { AES256 } from "@ionic-native/aes-256";
import { isPlatform } from "@ionic/core";
import { Drivers, Storage } from "@ionic/storage";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const KEYCHAIN_IDENTIFIER_SECRET = "HSC_SCLIENT_SECRET_KEY";
const KEYCHAIN_IDENTIFIER_IV = "HSC_SCLIENT_IV";

/**
 * Secure database layer
 *
 * This module encapsulates @ionic/storage database and adds an encryption layer to it.
 * @ionic-native/aes-256 is used to natively derive keys and en-/decrypt data on the device.
 * capacitor-secure-storage-plugin is used to store secrets on the devices' keychain.
 *
 * Important note: in a web/browser platform, values are stored unencrypted!
 */
export class SecureDb {
  /**
   * The symmetric AES256 encryption key
   */
  secretKey: string | undefined;

  /**
   * The AES256 initialization vector
   */
  iv: string | undefined;

  /**
   * False, until SecureDb has been initialized
   */
  ready = false;

  /**
   * Place to store callback references
   */
  readyHandlers = useCallbacks();

  /**
   * Database (@ionic/storage)
   */
  db: Storage | undefined;

  /**
   * Initializes SecureDb
   *
   * 1) Initializes @ionic/storage
   * 2) Reads key + IV from keychain or generates and saves them to keychain if not present
   * 3) marks SecureDb as ready
   */
  init = async () => {
    await this.initIonicStorage();

    if (isPlatform("cordova")) {
      // read secretKey and IV (or generate if first time)
      this.secretKey = await this.loadFromKeychain(KEYCHAIN_IDENTIFIER_SECRET);
      this.iv = await this.loadFromKeychain(KEYCHAIN_IDENTIFIER_IV);

      if (!this.secretKey || !this.iv) {
        // first time, generate key and iv
        await this.generateKeyAndIV();
        // save them to keychain
        if (this.secretKey && this.iv) {
          await this.saveToKeychain(KEYCHAIN_IDENTIFIER_SECRET, this.secretKey);
          await this.saveToKeychain(KEYCHAIN_IDENTIFIER_IV, this.iv);
        } else {
          throw new Error("SecureDb initialization failed");
        }
      }
    } else {
      // in browser, skip as native functionality is not available
    }

    this.markAsReady();
  };

  /**
   * Initializes the database (@ionic/storage).
   *
   * This only has to be done once on application startup.
   */
  initIonicStorage = async () => {
    let ionicStorage = new Storage({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
    });
    ionicStorage = await ionicStorage.create();
    if (isPlatform("hybrid")) {
      await ionicStorage.defineDriver(CordovaSQLiteDriver);
    }
    this.db = ionicStorage;
  };

  /**
   * Generates a 128 char secure random string
   *
   * @returns Returns the secure random string
   */
  generateSecureRandomString = () => {
    const secureRandomBytes = new Uint8Array(128);
    window.crypto.getRandomValues(secureRandomBytes);
    return String.fromCharCode(...secureRandomBytes);
  };

  /**
   * Saves a key:value pair to device keychain
   *
   * @param keychainIdentifier device keychain identifier (key)
   * @param value value to save to keychains
   */
  saveToKeychain = async (keychainIdentifier: string, value: string) => {
    await SecureStoragePlugin.set({
      key: keychainIdentifier,
      value: value,
    });
  };

  /**
   * Loads a value from device keychain
   *
   * @param keychainIdentifier device keychain identifier (key)
   * @returns Returns the stored value or undefined if key not set
   */
  loadFromKeychain = async (keychainIdentifier: string): Promise<string | undefined> => {
    try {
      const { value } = await SecureStoragePlugin.get({
        key: keychainIdentifier,
      });
      return value;
    } catch (e) {
      return undefined;
    }
  };

  /**
   * Generates AES256 key + IV based on a cryptographically random string
   */
  generateKeyAndIV = async () => {
    const secureRandomString = this.generateSecureRandomString();
    this.secretKey = await AES256.generateSecureKey(secureRandomString); // Returns a 32 bytes string
    this.iv = await AES256.generateSecureIV(secureRandomString); // Returns a 16 bytes string
  };

  /**
   * Encrypts data
   *
   * @param plainText data to encrypt
   * @returns Returns cipher text (encrypted data)
   */
  encrypt = async (plainText: string) => {
    if (!this.secretKey || !this.iv) throw new Error("key or iv not set");
    return await AES256.encrypt(this.secretKey, this.iv, plainText);
  };

  /**
   * Decrypts data
   *
   * @param cipherText aata to decrypt
   * @returns Returns plain text (unencrypted data)
   */
  decrypt = async (cipherText: string) => {
    if (!this.secretKey || !this.iv) throw new Error("key or iv not set");
    return await AES256.decrypt(this.secretKey, this.iv, cipherText);
  };

  /**
   * Encrypts and stores the value for the given key.
   *
   * @param key the key to identify this value
   * @param value the value for this key
   * @returns Returns a promise that resolves when the key and value are set
   */
  set = async (key: string, value: string) => {
    if (!this.db) throw new Error("ionic storage not initialized");
    if (isPlatform("cordova")) {
      await this.db.set(key, await this.encrypt(value));
    } else {
      // in browser, skip encryption as native functionality is not available
      await this.db.set(key, value);
    }
  };

  /**
   * Loads and decrypts the value associated with the given key.
   *
   * @param key the key to identify this value
   * @returns Returns a promise with the value of the given key
   */
  get = async (key: string) => {
    if (!this.db) throw new Error("ionic storage not initialized");
    if (isPlatform("cordova")) {
      return await this.decrypt(await this.db.get(key));
    } else {
      // in browser, skip decryption as native functionality is not available
      return await this.db.get(key);
    }
  };

  /**
   * Remove any value associated with this key.
   *
   * @param key the key to identify this value
   * @returns Returns a promise that resolves when the value is removed
   */
  remove = async (key: string) => {
    if (!this.db) throw new Error("ionic storage not initialized");
    return await this.db.remove(key);
  };

  /**
   * Clear the entire key value store. WARNING: HOT!
   *
   * @returns Returns a promise that resolves when the store is cleared
   */
  clear = async () => {
    if (!this.db) throw new Error("ionic storage not initialized");
    return await this.db.clear();
  };

  /**
   * Asynchronous notification when SecuredDb has finished its initialization
   * Inspired by: https://unpkg.com/browse/vue-router@4.0.0-beta.9/dist/vue-router.cjs.js
   *
   * @returns Returns a promise that resolves or reject when the SecureDb has finished
   * its initialization
   */
  isReady = async () => {
    if (this.ready) return Promise.resolve();
    return new Promise((resolve, reject) => {
      this.readyHandlers.add([resolve, reject]);
    });
  };

  /**
   * Mark SecureDb as ready, resolving the promised returned by isReady(). Can
   * only be called once, otherwise does nothing.
   * Inspired by: https://unpkg.com/browse/vue-router@4.0.0-beta.9/dist/vue-router.cjs.js
   *
   * @param err - optional error
   */
  markAsReady = (err?: Error) => {
    if (this.ready) return;
    this.ready = true;
    this.readyHandlers.list().forEach(([resolve, reject]) => (err ? reject(err) : resolve()));
    this.readyHandlers.reset();
  };
}

interface CallbackFn {
  (reason?: any): void;
}

/**
 * Create a list of callbacks that can be reset.
 * Inspired by: https://unpkg.com/browse/vue-router@4.0.0-beta.9/dist/vue-router.cjs.js
 */
const useCallbacks = () => {
  let handlers: CallbackFn[][] = [];
  function add(handler: CallbackFn[]) {
    handlers.push(handler);
    return () => {
      const i = handlers.indexOf(handler);
      if (i > -1) handlers.splice(i, 1);
    };
  }
  function reset() {
    handlers = [];
  }
  return {
    add,
    list: () => handlers,
    reset,
  };
};

/**
 * Creates new SecureDb and starts its asynchronous initialization.
 * Wait until initialization has finished before using it: use isReady().then()
 *
 * @returns Returns a new SecureDb object
 */
const createSecureDb = () => {
  const secureDb = new SecureDb();
  secureDb.init();
  return secureDb;
};

const secureDb = createSecureDb();

export const useSecureDb = () => {
  return secureDb;
};

export default secureDb;
