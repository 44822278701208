import {init as initQuestionnaires} from '@/modules/questionnaires/init';
import {accountDal} from '@/modules/account/data/dal/account';
import databaseSeeder from '@/data/databaseSeeder';
export const initModules = async () => {

 /** ----------------------------------
  * Seeds database
  ---------------------------------- */
 try {
  await accountDal.read();
 } catch (e) {
  await databaseSeeder.reseed();
 }

 await initQuestionnaires();
}
