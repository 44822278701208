import { Account } from "../model/account";
import secureDb from "@/utils/secureDb";
import Serializer from "@/utils/serializer";

export const PREFIX = "account";

const create = async (account: Account) => {
  await secureDb.set(PREFIX, Serializer.stringify(account));
};

const read = async (): Promise<Account> => {
  const json = await secureDb.get(PREFIX);
  if (json) return Serializer.parse(json) as Account;
  else throw new Error("Unable to read account");
};

const exists = async (): Promise<boolean> => {
  try {
    await read();
    return true;
  } catch (e) {
    return false;
  }
};

const update = async (account: Account) => {
  await secureDb.set(PREFIX, Serializer.stringify(account));
};

const remove = async () => {
  await secureDb.remove(PREFIX);
};

/**
 * CRUD database access to account
 * This repository does not implement CRUDRepository interface
 * as account does not have an id
 */
const accountCRUDRepository = {
  create,
  read,
  exists,
  update,
  remove,
};

export default accountCRUDRepository;
