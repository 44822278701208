import { defineStore } from 'pinia'
import {Questionnaire} from '@/modules/questionnaires/data/model/questionnaire';
import {loadLocalQuestionnaires} from '@/modules/questionnaires/utils/configLoader';

interface State {
    questionnaires: Map<string, Questionnaire>;
}

export const useQuestionnaireStore = defineStore('questionnaire', {
    // convert to a function
    state: (): State => ({
        questionnaires: new Map<string, Questionnaire>(),
    }),
    getters: {
        get: (state) => (questionnaireId : string) =>
            state.questionnaires.get(questionnaireId),
        ids: (state) => Array.from(state.questionnaires.keys()),
        getAll: (state): Array<Questionnaire> => Array.from(state.questionnaires.values()),
    },
    actions: {
        // no context as first argument, use `this` instead
        // mutations can now become actions, instead of `state` as first argument use `this`
        async loadQuestionnaires () {
            this.clearQuestionnaires();
            const questionnaires = await loadLocalQuestionnaires();
            questionnaires.forEach((questionnaire) => {
                if (questionnaire.id != null) {
                    this.add(questionnaire.id, questionnaire);
                } else {
                    console.error('Questionnaire without id', questionnaire);
                }
            });
        },
        add (questionnaireId: string, questionnaire: Questionnaire) {
            this.questionnaires.set(questionnaireId, questionnaire);
        },
        remove (questionnaireId: string) {
            this.questionnaires.delete(questionnaireId);
        },
        // easily reset state using `$reset`
        clearQuestionnaires () {
            this.$reset()
        }
    }
})
