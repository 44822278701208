import { Answersheet } from "../model/answersheet";
import secureDb from "@/utils/secureDb";
import Serializer from "@/utils/serializer";
import { v4 as uuidv4 } from "uuid";
import { CRUDRepository } from "@/utils/repositories";

export const PREFIX = "answersheet.";
const NOT_FOUND_ERROR_MSG = "Unable to find entity with id: ";

const create = async (answersheet: Answersheet): Promise<string> => {
  // set new answersheet.id only, if not exists
  if (!answersheet.id) answersheet.id = uuidv4();
  await secureDb.set(PREFIX + answersheet.id, Serializer.stringify(answersheet));
  return answersheet.id;
};

const read = async (id: string): Promise<Answersheet> => {
  const json = await secureDb.get(PREFIX + id);
  if (json) return Serializer.parse(json) as Answersheet;
  else throw new Error(NOT_FOUND_ERROR_MSG + id);
};

const readMany = async (ids: string[]): Promise<Answersheet[]> => {
  const answersheets: Answersheet[] = [];
  for (const id of ids) {
    answersheets.push(await read(id));
  }
  return answersheets;
};

const exists = async (id: string) => {
  try {
    await read(id);
    return true;
  } catch (e) {
    return false;
  }
};

const update = async (answersheet: Answersheet): Promise<void> => {
  if (!answersheet.id) throw new Error("answersheet.id not set");
  await secureDb.set(PREFIX + answersheet.id, Serializer.stringify(answersheet));
};

const remove = async (id: string): Promise<void> => {
  await secureDb.remove(PREFIX + id);
};

const removeMany = async (ids: string[]): Promise<void> => {
  for (const id of ids) await remove(id);
};

/**
 * CRUD database access to answer sheets
 */
const answerSheetCRUDRepository: CRUDRepository<Answersheet> = {
  create,
  read,
  readMany,
  exists,
  update,
  remove,
  removeMany,
};

export default answerSheetCRUDRepository;
