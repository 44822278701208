import submissionQueueRepository from '@/modules/api/data/repositories/submissionQueueRepository';

const create = async (queue: SubmissionQueue) => {
  await submissionQueueRepository.create(queue);
};

const read = async () => {
  return await submissionQueueRepository.read();
};

const update = async (queue: SubmissionQueue) => {
  await submissionQueueRepository.update(queue);
};

const pushElement = async (element: SubmissionQueueElement) => {
  const queue = await submissionQueueRepository.read();
    if (!queue) {
        return;
    }
    queue.elements.push(element);
    await submissionQueueRepository.update(queue);
};

const shiftElement = async () => {
  const queue = await submissionQueueRepository.read();
  if (!queue) {
      return;
  }
  queue.elements.shift();
  await submissionQueueRepository.update(queue);
}

export const submissionQueueDal = {
  create,
  read,
  update,
  pushElement,
  shiftElement,
};
