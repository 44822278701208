import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonTabButton = _resolveComponent("IonTabButton")!
  const _component_IonTabBar = _resolveComponent("IonTabBar")!
  const _component_IonTabs = _resolveComponent("IonTabs")!
  const _component_IonPage = _resolveComponent("IonPage")!

  return (_openBlock(), _createBlock(_component_IonPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonTabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_IonRouterOutlet),
          _createVNode(_component_IonTabBar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_IonTabButton, {
                tab: "home",
                href: "/tabs/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonIcon, {
                    "aria-hidden": "true",
                    icon: $setup.homeOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_IonLabel, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('tab.home')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_IonTabButton, {
                tab: "questionnaires",
                href: "/tabs/questionnaires"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonIcon, {
                    "aria-hidden": "true",
                    icon: $setup.documentTextOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_IonLabel, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('tab.questionnaires')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_IonTabButton, {
                tab: "history",
                href: "/tabs/history"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonIcon, {
                    "aria-hidden": "true",
                    icon: $setup.listOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_IonLabel, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('tab.history')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}