
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import { homeOutline, documentTextOutline, listOutline } from 'ionicons/icons';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
export default {
  name: 'TabsPage',
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const route = useRoute();

    return {
      t,
      homeOutline,
      documentTextOutline,
      listOutline,
      route,
    };
  },
};
