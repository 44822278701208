export class Account {

  public modelVersion = 1; // version of the model, used for migrations
  public email: string | undefined;
  public name: string | undefined;
  public birthdate: number | undefined;
  public pseudonym: string | undefined; // pseudonym of the user, typically a UUID
  public questionnaireApiKey: string | undefined;
  public settings = new AccountSettings();
  public onboarding = new Onboarding();
  public userprofileId: string | undefined;
  public answersheets = new Set<string>();

}

/**
 * Interface for the settings of the account
 * @interface
 * @property {string} language - language of the account, the user interface is displayed in
 */
export class AccountSettings {

  public language: string | undefined;
}

/**
 * Interface for the onboarding of the user
 * @interface
 * @property {boolean} done - true if the onboarding is done
 * @property {Consents} consents - consents of the onboarding
 */
export class Onboarding {
  public done = false;
  public consents = new Consents();
}

/**
 * Interface for the consents of the onboarding
 * @interface
 * @property {boolean} dataSharingConsent - consent for data sharing
 * @property {boolean} risksConsent - consent for risks
 * @property {boolean} tosDprConsent - consent for terms of service and data protection
 * @property {boolean} appAnalyticsConsent - consent for app analytics
 */
export class Consents {
  public dataSharingConsent = false;
  public risksConsent = false;
  public tosDprConsent = false;
  public appAnalyticsConsent = false;
}

