import { Answersheet } from "../data/model/answersheet";
import {AnswersheetAttributesDto, AnswersheetDto} from '@/modules/questionnaires/data/model/answersheetDto';
import {convertUndefined} from '@/utils/serializer';

export const sortAnswersheets = (answersheets: Array<Answersheet>) => {
  // sort on unix date string answersheet.attributes.collected_at
  return answersheets.sort((n1, n2) => {
    if (!n1.attributes?.collected_at || !n2.attributes?.collected_at) return 0;
    if (n1.attributes?.collected_at < n2.attributes.collected_at) {
      return 1;
    }
    if (n1.attributes?.collected_at > n2.attributes.collected_at) {
      return -1;
    }
    return 0;
  });
};

export const convertToDto = (answersheet: Answersheet) => {
  const attributesDto = { ...answersheet.attributes } as unknown as AnswersheetAttributesDto;
  if(answersheet.attributes?.answers.values())
    attributesDto.answers = Array.from(answersheet.attributes?.answers.values());
  return convertUndefined({ ...answersheet, attributes: attributesDto } as AnswersheetDto, null);
}
