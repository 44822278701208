import { Factory } from "fishery";
import { Account, AccountSettings, Onboarding } from "./account";

const AccountFactory = Factory.define<Account>(() => ({
  modelVersion: 1,
  email: undefined,
  name: undefined,
  birthdate: undefined,
  pseudonym: undefined,
  questionnaireApiKey: undefined,
  settings: new AccountSettings(),
  onboarding: new Onboarding(),
  userprofileId: undefined,
  children: new Set<string>(),
  articleFavorites: new Set<string>(),
  answersheets: new Set<string>(),
}));

export default AccountFactory;
