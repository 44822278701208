import { Questionnaire } from "@/modules/questionnaires/data/model/questionnaire";

export const loadLocalQuestionnaires = async () => {
  const questionnaires = require.context(
    "../../../config/questionnaires",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const result: Questionnaire[] = [];
  for (const data of questionnaires.keys()) {
    const questionnaire: Questionnaire = new Questionnaire(questionnaires(data));
    result.push(questionnaire);
  }
  return result;
};
