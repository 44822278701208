import {ConnectionStatus, Network} from '@capacitor/network';
import { isPlatform } from '@ionic/vue';


/**
 * Returns true if the app is running on capacitor or cordova,
 * so we can rely on capacitor or cordova to get the connection status.
 */
const isCapacitorOrCordova = async () => {
    return isPlatform('capacitor') || isPlatform('cordova');
};

/**
 * Returns the current connection status.
 * ConnectionStatus is an object with the following properties:
 * - connected: boolean
 * - connectionType: string
 * , where connectionType can be one of the following:
 * - wifi
 * - cellular
 * - none
 * - unknown
 */
export const getConnectionStatus = async (): Promise<ConnectionStatus> => {
    // check, if ionic is running in a browser or on a mobile device
    if(await isCapacitorOrCordova()) {
        // rely on capacitor or cordova to get the connection status
        return await Network.getStatus();
    } else {
        // ionic is running in a browser, so we have to check the navigator object
        if (navigator.onLine) {
            return {connected: true, connectionType: 'wifi'};
        }
        return {connected: false, connectionType: 'none'};
    }
}

/**
 * Returns true if the device is connected to the internet.
 */
export const isOnline = async (): Promise<boolean> => {
    const status = await getConnectionStatus();
    return status.connected;
}

/**
 * Add a listener to the network status change event.
 * @param callback The callback function to be called when the network status changes.
 */
export const addConnectionListener = async (callback: (status: ConnectionStatus) => void) => {
    if(await isCapacitorOrCordova()) {
        Network.addListener('networkStatusChange', callback);
        console.log('Added network status change listener.');
    } else {
        window.addEventListener('online', () => callback({connected: true, connectionType: 'wifi'}));
        window.addEventListener('offline', () => callback({connected: false, connectionType: 'none'}));
        console.log('Added online/offline event listeners.');
    }
}

/**
 * Remove all network status change listeners.
 */
export const removeAllConnectionListeners = async () => {
    if(await isCapacitorOrCordova()) {
        await Network.removeAllListeners();
    } else {
        window.removeEventListener('online', () => { return; });
        window.removeEventListener('offline', () => { return; });
    }

}
